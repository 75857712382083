.Spedizioni {
    color: black;
    width: 75%;
    text-align: -webkit-center;
    padding: 10px;
    box-sizing: border-box;
}

.Title {
    color: #c1ca80;
}

.FiltriBox {
    border-radius: 4px;
    box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
    padding: 20px;
    margin-top: 5%;
}

.ResultBox {
    border-radius: 4px;
    box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
    padding: 20px;
    margin-top: 1%;
}

.FiltriTitle {
    margin-top: 10px;
    color: #768828;
}
