.Modal {
    position: fixed;
    z-index: 500;
    background-color: white;
    width: 70%;
    border: 1px solid #ccc;
    box-shadow: 1px 1px 1px black;
    padding: 16px;
    left: 15%;
    top: 20%;
    box-sizing: border-box;
    transition: all 0.3s ease-out;
    overflow-y: auto;
    max-height: 720px;
}

.ModalDialog {
    position: fixed;
    z-index: 500;
    background-color: white;
    width: 30%;
    border: 1px solid #ccc;
    box-shadow: 1px 1px 1px black;
    padding: 16px;
    left: 33%;
    top: 20%;
    box-sizing: border-box;
    transition: all 0.3s ease-out;
    overflow-y: auto;
    max-height: 250px;
}

.Packaging {
    position: fixed;
    z-index: 500;
    background-color: white;
    width: 20%;
    left: 80%;
    border: 3px solid #768828;
    padding: 16px;
    top: 20%;
    box-sizing: border-box;
    transition: all 0.3s ease-out;
}

.Packaging button {
    width: 120%;
    right: 20%;
}

.Devices {
    position: fixed;
    z-index: 500;
    background-color: white;
    width: 20%;
    right: 80%;
    border: 3px solid #768828;
    padding: 16px;
    top: 10%;
    box-sizing: border-box;
    transition: all 0.3s ease-out;
    height: 1000px !important;
}

.Devices button {
    width: 120%;
    left: 0;
    top: -10px
}

.Anagrafica {
    position: fixed;
    z-index: 500;
    background-color: white;
    width: 70%;
    border: 1px solid #ccc;
    box-shadow: 1px 1px 1px black;
    padding: 16px;
    left: 15%;
    top: 20%;
    box-sizing: border-box;
    transition: all 0.3s ease-out;
}

.ModalAccount {
    position: fixed;
    z-index: 500;
    background-color: white;
    width: 50%;
    border: 1px solid #ccc;
    box-shadow: 1px 1px 1px black;
    padding: 16px;
    left: 33%;
    top: 30%;
    box-sizing: border-box;
    transition: all 0.3s ease-out;
    overflow-y: auto;
    max-height: 400px;
}

/*@media (min-width: 600px) {*/
/*    .Modal {*/
/*        width: 500px;*/
/*        left: calc(50% - 250px);*/
/*    }*/
/*}*/
