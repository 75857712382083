.Magazzino {
    /*color: black;*/
    width: 90%;
    text-align: -webkit-center;
    padding: 10px;
    box-sizing: border-box;
}

.Title {
    margin-top: 60px;
    color: #c1ca80;
}

.MuiDataGrid-root .MuiDataGrid-colCellTitleContainer {
    flex: 1;
    display: inline-flex;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.FiltriBox {
    border-radius: 4px;
    box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
    padding: 20px;
    margin-top: 5%;
}

.FiltriTitle {
    margin-top: 10px;
    color: #768828;
}


