.NavigationItem {
    margin: 10px 0;
    box-sizing: border-box;
    display: block;
    width: 100%;
    color: black;
    border: 1px solid #ccc;
    text-align: center;
    padding: 0 30px;
}

.NavigationItem a {
    color: #000;
    text-decoration: none;
    width: 100%;
    box-sizing: border-box;
    display: block;
}

.NavigationItem a:hover,
.NavigationItem a:active,
.NavigationItem a.active {
    color: #bbb;
}

/*@media (min-width: 500px) {*/
/*    .NavigationItem {*/
/*        margin: 0;*/
/*        box-sizing: border-box;*/
/*        display: flex;*/
/*        height: 100%;*/
/*        width: auto;*/
/*        align-items: center;*/
/*    }*/

/*    .NavigationItem a {*/
/*        color: #fff;*/
/*        text-decoration: none;*/
/*        height: 100%;*/
/*        padding: 16px 10px;*/
/*        border-bottom: 4px solid transparent;*/
/*        box-sizing: border-box;*/
/*        display: block;*/
/*    }*/

/*    .NavigationItem a:hover,*/
/*    .NavigationItem a:active,*/
/*    .NavigationItem a.active {*/
/*        background-color: #8F5C2C;*/
/*        border-bottom: 4px solid #40A4CB;*/
/*        color: white;*/
/*    }*/
/*}*/
