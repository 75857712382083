.Coupon {
    color: black;
    width: 80%;
    text-align: -webkit-center;
    padding: 10px;
    box-sizing: border-box;
}

.Title {
    /*margin-top: 60px;*/
    color: #c1ca80;
}

.CouponSnackbar {
    top: 200px;
    left: auto;
    right: 200px;
}

.Print {
    max-width: 100%;
    overflow: auto;
    margin-bottom: 20px;
}

.CouponField {
    width: 100%;
}
