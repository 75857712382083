.BuyDevice {
    color: black;
    width: 75%;
    text-align: -webkit-center;
    padding: 10px;
    box-sizing: border-box;
}

.Title {
    margin-top: 60px;
    color: #c1ca80;
}

.FiltriBox {
    border-radius: 4px;
    box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
    padding: 20px;
    margin-top: 5%;
}

.ResultBox {
    border-radius: 4px;
    box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
    padding: 5px;
    margin-top: 1%;
}

.FiltriTitle {
    margin-top: 10px;
    color: #768828;
}

.TestTitle {
    margin-top: 0px;
    color: #768828;
    font-size: 22px;
}

.ErrorTitle {
    margin-top: 0px;
    font-size: 22px;
    color: crimson;
}

.AppDownload {
    width: 250px;
    display: inline-block;
    margin: 10px
}
.AppInstruction {
    width: 35%;
    display: inline-block;
    margin: 10px
}

.UndexText {
    color: #768828;
    font-family:  -apple-system, BlinkMacSystemFont, 'Montserrat',
    sans-serif;
    font-size: 22px;
}

.CardLabel {
    margin-bottom: 20px;
}

.PassedTest {
    text-align: -webkit-center;
    padding: 3px;
    margin: 3px;
    box-sizing: border-box;
    border-radius: 25px;
    border: 2px solid #768828;
    background-color: #c1ca80;
    color: black;
}

.FailedTest {
    text-align: -webkit-center;
    padding: 3px;
    margin: 3px;
    box-sizing: border-box;
    border-radius: 25px;
    border: 2px solid red;
    background-color: red;
    color: white;
}

.ManualTest {
    text-align: -webkit-center;
    padding: 3px;
    margin: 3px;
    box-sizing: border-box;
    border-radius: 25px;
    border: 2px solid #e38903;
    background-color: #e38903;
    color: white;
}

.Side {
    display: inline-block;
    background-color: #cccccc;
    width: 20%;
    margin-left: 1%;
}

.RadioLabel {
    margin: 10px;
}

.GradoEsteticoBox {
    border-radius: 4px;
    padding: 10px;
    margin-top: 1%;
}

.Info {
    text-align: -webkit-center;
    padding: 3px;
    margin: 3px;
    box-sizing: border-box;
    border-radius: 25px;
    border: 2px solid #768828;
    color: black;
    width: 70%;
}

.Sticky {
    position: sticky;
    top: 100px;
    padding: 10px 16px;
    background: #666;
    color: #f1f1f1;
    z-index: 5000;
    border: 3px solid #c1ca80;
}

.ResultBoxSmall {
    border-radius: 4px;
    box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
    /*padding: 2px;*/
    margin: 2px;
    display: inline-block;
    width: 48%;
    max-height: 500px;
    vertical-align: top;
}

.Paragrafo {
    font-size: x-large;
    margin: 5px 0 0 0;
}
