.Auth {
    margin: 20px auto;
    width: 80%;
    text-align: center;
    /*box-shadow: 0 2px 3px #ccc;*/
    /*border: 1px solid #eee;*/
    padding: 10px;
    box-sizing: border-box;
}

.Input {
    display: block;
}

@media (min-width: 600px) {
    .Auth {
        width: 500px;
    }
    .HPToolbar {
        max-height: 55px;
    }
}

.HPToolbar {
    max-height: 55px;
}

.DocContainer {
    max-height: 100%;
    max-width: 100%;
    margin-bottom: 20px;
}

.Captcha {
    text-align: -webkit-center;
}
