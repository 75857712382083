.Input {
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
}

.Input option:disabled {
    background-color: #ccc;
}

.Input:focus,
.Input option:focus {
    background-color: white;
}

.Label {
    font-weight: bold;
    display: block;
    margin-bottom: 8px;
    color: #7f3608;
}

.InputElement {
    outline: none;
    border: 1px solid #cccccc;
    background-color: white;
    font: inherit;
    padding: 6px 10px;
    box-sizing: border-box;
    width: 100%;
}

.InputElement:focus {
    outline: none;
    background-color: #fff;
    display: block;
    width: 100%;
}

.Invalid {
    border: 1px solid red;
}

.Valid {
    border: 1px solid green;
}

.ValidationError {
    color: red;
}
