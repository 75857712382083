.Servizi {
    color: black;
    width: 60%;
    text-align: center;
    padding: 10px;
    box-sizing: border-box;
}

.Title {
    margin-top: 60px;
    color: #c1ca80;
}

.Disactive {
    width: 200px;
    display: inline-block;
    margin: 20px;
    /*background-color: #777 !important;*/
    cursor: not-allowed;
}

.Active {
    width: 200px;
    display: inline-block;
    margin: 20px
}

.IconActive {
    font-size: 200px !important;
    color: #c1ca80
}

.IconDisactive {
    font-size: 200px !important;
    color: #777;
    cursor: not-allowed;
}

.CardAreaImg {
    height: 300px;
    border: 2px solid blue;
    border-radius: 40px;
    width: 300px;
}


.ImgActive {
    display: inline-block;
    margin-top: 22%;
    width: 70%
}


.ImgDisactive {
    display: inline-block;
    margin-top: 22%;
    width: 70%;
    opacity: 0.2;
    border-radius: 55px;
    cursor: default;
    /*width: 176px*/
}


.UndexText {
    color: #c1ca80;
    font-family:  -apple-system, BlinkMacSystemFont, 'Montserrat',
    sans-serif;
    font-size: 22px;
}

.ComingSoon {
    color: #444444;
    font-family:  -apple-system, BlinkMacSystemFont, 'Montserrat',
    sans-serif;
    font-size: 18px;
}
