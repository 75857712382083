.ElementoTabella {
    text-align: -webkit-center;
    padding: 3px;
    margin: 3px;
    box-sizing: border-box;
    border-radius: 25px;
    border: 2px solid #768828;
}

.FiltriBox {
    border-radius: 4px;
    box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
    padding: 10px;
    margin-top: 1%;
}

.PassedTest {
    text-align: -webkit-center;
    padding: 3px;
    margin: 3px;
    box-sizing: border-box;
    border-radius: 25px;
    border: 2px solid #768828;
    background-color: #c1ca80;
    color: black;
}

.FailedTest {
    text-align: -webkit-center;
    padding: 3px;
    margin: 3px;
    box-sizing: border-box;
    border-radius: 25px;
    border: 2px solid red;
    background-color: red;
    color: white;
}
