.Container {
    width: 100%;
    height: 100%;
    top: 10%;
    left: 10%;
}

.SigContainer {
    width: 98%;
    height: 250px;
    margin: 0 auto;
    background-color: #fff;
}

.SigPad {
    width: 100%;
    height: 100%;
}

.DocContainer {
    max-height: 400px;
    max-width: 100%;
    overflow: auto;
    margin-bottom: 20px;
}

.DocContainer img {
    margin-left: auto;
    margin-right: auto;
    display: block;
}

.Centered {
    text-align: center;
    text-align: -webkit-center;
}

.Print {
    max-width: 100%;
    overflow: auto;
    margin-bottom: 20px;
}

@media print {
    .NoPrint {
        visibility: hidden;
    }
}

