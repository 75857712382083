.Bolli {
    color: black;
    /*width: 75%;*/
    text-align: -webkit-center;
    padding: 10px;
    box-sizing: border-box;
}

.Title {
    /*margin-top: 60px;*/
    color: #c1ca80;
}
