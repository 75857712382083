.Logo {
    background-color: #ffffff;
    padding: 8px;
    box-sizing: border-box;
    border-radius: 5px;
    width: 100%;
    display: inline-block;
}

.Logo img {
    height: 85%;
    left: 10px;
    display: inline-block;
    position: absolute;
    top: -18px;
}

.Logo p {
    height: 100%;
    left: 10px;
    display: inline-block;
    position: absolute;
    font-size: x-small;
    top: 32px;
}

.LogoSmall {
    height: 50% !important;
    left: 10px;
    display: inline-block;
    position: absolute;
    top: -18px;
}

@media (max-width: 800px) {
    .Logo img {
        display: none;
    }
}
